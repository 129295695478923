import { combineReducers } from 'redux';

import { shopping } from './shopping/reducer';
import { shopping_despacho } from './shopping_despacho/reducer';
import { ecommerce } from './ecommerce/reducer';

const rootReducer = combineReducers({
  shopping,
  shopping_despacho,
  ecommerce,
});

export default rootReducer;
