import {
  CARGAR_ECOMMERCE_EXITO
} from './actions';

export const ecommerce = (state = {}, action) => {
  switch (action.type) {
    case CARGAR_ECOMMERCE_EXITO:
      return Object.assign({}, action.ecommerce);
    default:
      return state;
  }
};
