// Tipos de acciones
export const CARGAR_SHOPPING_DESPACHO_EXITO = 'CARGAR_SHOPPING_DESPACHO_EXITO';

// Creadores de acción
export const cargaShoppingDepachoExito = (shopping_despacho) => {
  return { type: CARGAR_SHOPPING_DESPACHO_EXITO, shopping_despacho };
};

export const addShoppingDespacho = (shopping_despacho, callback) => {
  return function (dispatch) {
    if (callback) {
      callback(shopping_despacho);
    }
    dispatch(cargaShoppingDepachoExito(shopping_despacho));
  };
};
