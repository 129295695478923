import {
  CARGAR_SHOPPING_EXITO
} from './actions';

export const shopping = (state = {}, action) => {
  switch (action.type) {
    case CARGAR_SHOPPING_EXITO:
      return Object.assign({}, action.shopping);
    default:
      return state;
  }
};
