import apiConfig from './config';

export default {
  getEcommerces: function (queryString, success, error) {

    const token = localStorage.getItem(apiConfig.constants.ACCESS_TOKEN_KEY) || null;
    return fetch(`${apiConfig.constants.url_api}/api/${apiConfig.constants.API_VERSION}/ecommerce${queryString}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json'
      }
    })
      .then(apiConfig.methods.checkStatus)
      .then(apiConfig.methods.parseJSON)
      .then(success)
      .catch(apiConfig.methods.error)
      .then(error);
  },

  getEcommerce: function (id, success, error) {
    const empresa_id = localStorage.getItem(apiConfig.constants.EMPRESA_ID) || null;
    const token = localStorage.getItem(apiConfig.constants.ACCESS_TOKEN_KEY) || null;
    return fetch(`${apiConfig.constants.url_api}/api/${apiConfig.constants.API_VERSION}/ecommerce/${id}?empresa_id=${empresa_id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json'
      }
    })
      .then(apiConfig.methods.checkStatus)
      .then(apiConfig.methods.parseJSON)
      .then(success)
      .catch(apiConfig.methods.error)
      .then(error);
  },

  createEcommerce: function (data, success, error) {
    const empresa_id = localStorage.getItem(apiConfig.constants.EMPRESA_ID) || null;
    const token = localStorage.getItem(apiConfig.constants.ACCESS_TOKEN_KEY) || null
    return fetch(`${apiConfig.constants.url_api}/api/${apiConfig.constants.API_VERSION}/ecommerce?empresa_id=${empresa_id}`, {
      method: 'post',
      body: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(apiConfig.methods.checkStatus)
      .then(apiConfig.methods.parseJSON)
      .then(success)
      .catch(apiConfig.methods.error)
      .then(error);
  },

  updateEcommerce: function (data, success, error) {
    const empresa_id = localStorage.getItem(apiConfig.constants.EMPRESA_ID) || null;
    const token = localStorage.getItem(apiConfig.constants.ACCESS_TOKEN_KEY) || null;
    return fetch(`${apiConfig.constants.url_api}/api/${apiConfig.constants.API_VERSION}/ecommerce/${data.id}?empresa_id=${empresa_id}`, {
      method: 'put',
      body: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(apiConfig.methods.checkStatus)
      .then(apiConfig.methods.parseJSON)
      .then(success)
      .catch(apiConfig.methods.error)
      .then(error);
  },

  deleteEcommerce: function (id, success, error) {
    const empresa_id = localStorage.getItem(apiConfig.constants.EMPRESA_ID) || null;
    const token = localStorage.getItem(apiConfig.constants.ACCESS_TOKEN_KEY) || null;
    return fetch(`${apiConfig.constants.url_api}/api/${apiConfig.constants.API_VERSION}/ecommerce/${id}?empresa_id=${empresa_id}`, {
      method: 'delete',
      headers: {
        Authorization: `Bearer ${token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(apiConfig.methods.checkStatus)
      .then(apiConfig.methods.parseJSON)
      .then(success)
      .catch(apiConfig.methods.error)
      .then(error);
  }
}