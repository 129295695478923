import React from 'react';

import './style.css';

class LoadingComponent extends React.Component {
  render() {
    return <div className='loader-body'>
      <div id='loader'>
        <div id='shadow'></div>
        <div id='box'></div>
      </div>
    </div>;
  }
}

export default LoadingComponent;
