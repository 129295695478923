// Tipos de acciones
export const CARGAR_SHOPPING_EXITO = 'CARGAR_SHOPPING_EXITO';

// Creadores de acción
export const cargaShoppingExito = (shopping) => {
  return { type: CARGAR_SHOPPING_EXITO, shopping };
};

export const addShopping = (shopping, callback) => {
  return function (dispatch) {
    if (callback) {
      callback(shopping);
    }
    dispatch(cargaShoppingExito(shopping));
  };
};
