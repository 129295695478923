export default {
  constants: {
    url_api: '',
    ACCESS_TOKEN_KEY: 'access_token',
    REFRESH_TOKEN_KEY: 'refresh_token',
    ACCESS_TOKEN_EXPIRES_IN: 'expires_in',
    ACCESS_TOKEN_EXPIRES_AT: 'expires_at',
    EMPRESA_ID: 'EMPRESA_ID',
    EMPRESA_USUARIO_ID: 'EMPRESA_USUARIO_ID',
    API_VERSION: 'v1',
    URL_CONTASISCORP_SOPORTE: 'https://soporte.contasiscorp.com',
    URL_ACCOUNTS: 'https://cuenta.contasiscorp.com',
    URL_CULQI: 'https://secure.culqi.com/v2/tokens',
  }
}
