import React from 'react';
import { Provider } from 'react-redux';
import Loadable from 'react-loadable';
import './App.css';
import 'antd/dist/antd.css';
import '@fortawesome/fontawesome-pro/css/all.css';
import store from './store';

import LoadingComponent from './components/Async/LoadingComponent';
const Public = Loadable({ loader: () => import('./scenes/Public/Public'), loading: LoadingComponent });

function App() {
  return (
    <Provider store={ store }>
      <div className="App">
        <Public/>
      </div>
    </Provider>
  );
}

export default App;
