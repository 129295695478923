import EcommerceService from '../../services/api/ecommerce';

// Tipos de acciones
export const CARGAR_ECOMMERCE_EXITO = 'CARGAR_ECOMMERCE_EXITO';

// Creadores de acción
export const cargaEcommerceExito = (ecommerce) => {
  return { type: CARGAR_ECOMMERCE_EXITO, ecommerce };
};

export const cargarEcommerce = (query, callback) => {
  return function (dispatch) {
    return EcommerceService.getEcommerces(query).then((ecommerce) => {
      if (ecommerce) {
        if (callback) {
          callback(ecommerce);
        }
        dispatch(cargaEcommerceExito(ecommerce));
      }
    }, error => {
      if (error) {
        throw (error);
      }
    }).catch(error => { throw (error); });
  };
};
